import React, { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';

interface Options {
  text: string;
  link: string;
}

interface Props {
  image: string;
  optionsArray: Options[];
}

const NavBar = (props: Props) => {
  const { image, optionsArray } = props;
  const offset = 400;
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset >= offset) { setSticky(true); } else { setSticky(false); }
    }
    return function cleanup() {
      window.onscroll = null;
    };
  }, []);

  const mOptions = optionsArray.map((option: Options) => (
    <Nav.Link href={option.link} key={option.text} className="navLink">{option.text}</Nav.Link>
  ));

  const stickyStyles: React.CSSProperties = {
    background: '#ffffff',
    boxShadow: '0px 45px 183px rgba(5, 2, 61, 0.06), 0px 18.7999px 76.453px rgba(5, 2, 61, 0.0431313), 0px 10.0513px 40.8754px rgba(5, 2, 61, 0.0357664), 0px 5.6347px 22.9144px rgba(5, 2, 61, 0.03), 0px 2.99255px 12.1697px rgba(5, 2, 61, 0.0242336), 0px 1.24527px 5.06408px rgba(5, 2, 61, 0.0168687)',
  }

  return (
    <Navbar sticky={sticky ? 'top' : undefined} style={sticky ? stickyStyles : {}} collapseOnSelect variant='light' expand="sm" >
      <Container>
        <Navbar.Brand href='/' >
          <img className="navBarImg" src={image} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggler-css" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            {mOptions}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
