import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import {
  linkedinImg,
  facebookImg,
  githubImg,
  twitterImg
} from '../images';

interface CardsFeatured {
  id: number;
  title: string;
  paragraph?: string;
  date?: string;
  tags?: string[];
  links?: any[];
}

interface Props {
  title: string;
  cardFeatured: CardsFeatured[];
  images: string[];
  onClick: any;
  linkText?: string;
  selectedFeaturedCard?: number | null;
}

const getMappedCards = (cards: CardsFeatured[], images: string[], onClick: any, linkText?: string, selectedFeaturedCard?: number | null) => cards.map((card: CardsFeatured, i: number) => {
  let mTags:any = [];
  if (card.tags) {
    mTags = card.tags.map((tag: string) => (
      <>
        <Col>{tag}</Col>
      </>
    ))
  }

  let mLinks: any = [];
  if (card.links && card.links.length) {
    mLinks = card.links.map((link: any) => {
      let img = '';
      if (link.type === 'linkedin') {
        img = linkedinImg;
      } else if (link.type === 'facebook') {
        img = facebookImg;
      } else if (link.type === 'github') {
        img = githubImg;
      } else if (link.type === 'twitter') {
        img = twitterImg;
      }
      return (
        <a href={link.link} target="__blank"><img src={img} alt=""/></a>
      )
    });
  }

  return (
    <>
      <Card className="cardFeatured" onClick={() => onClick(card.id)}>
        <Card.Img className="cardFeaturedImage" src={images[i]} alt="" />
        <Card.Body>
          <Row>
            {mTags}
          </Row>
          <h1 className="cardFeaturedCardTitle">{card.title}</h1>
          {card.paragraph ? <p className="cardFeaturedCardSubtitle">{card.paragraph}</p> : ""}
          {mLinks && mLinks.length ? <div className="cardFeaturedCardLinks">{mLinks}</div> : ''}
          <Row>
            <Col>
              <Card.Link href="#">{linkText}</Card.Link>
            </Col>
            <Col>{card.date}</Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
})

const CardFeatured = (props: Props) => {
  const {
    title, linkText, cardFeatured, images, onClick, selectedFeaturedCard,
  } = props;
  return (
    <>
      <div className="cardFeaturedWrapper">
        <h1 className="cardFeaturedTitle">{title}</h1>
        <div className="cardGroupWrapper">
          <CardGroup className="cardGroup">
            {getMappedCards(cardFeatured, images, onClick, linkText, selectedFeaturedCard)}
          </CardGroup>
        </div>
      </div>
    </>
  );
};

export default CardFeatured;
