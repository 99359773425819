import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from '../components/navBar';
import Footer from '../components/footer';
import { constants } from '../content';
import {
  logoDarkImg,
  logoWhiteImg,
  facebookFooterImg,
  twitterFooterImg,
  instagramFooterImg,
  linkedinFooterImg,
  youtubeFooterImg,
  eBannerEU,
  elevateWebEn,
  elevateWeb,
  usolveLogo
} from '../images';
import CoFinanced from "../components/coFinanced";

const CoFinancedByGREU = () => {
  return (
  <>
    <NavBar
      image={logoDarkImg}
      optionsArray={constants.navBar}
    />
    <Container>
      <CoFinanced
        title={constants.coFinancedByGrEu.title}
        subtitle={constants.coFinancedByGrEu.subtitle}
        description={constants.coFinancedByGrEu.description}
        imageElavateWebEN={elevateWebEn}
        subtitleGr={constants.coFinancedByGrEu.subtitleGr}
        descriptionGr={constants.coFinancedByGrEu.descriptionGr}
        imageElavateWeb={elevateWeb}
      />
      <Footer
        image={logoWhiteImg}
        paragraph={constants.footer.paragraph}
        paragraphEU={constants.footer.paragraphEU}
        imageEU={eBannerEU}
        menuTitle={constants.footer.menuTitle}
        menuLinks={constants.footer.menuLinks}
        followTitle={constants.footer.followTitle}
        followLinks={constants.footer.followLinks}
        followImageArray={[facebookFooterImg, twitterFooterImg, instagramFooterImg, linkedinFooterImg, youtubeFooterImg]}
        contactTitle={constants.footer.contactTitle}
        contactLinks={constants.footer.contactLinks}
        copyright={constants.footer.copyright}
        privacyLinks={constants.footer.privacyLinks}
        termsLinks={constants.footer.termsLinks}
        coFinancedLinks={constants.coFinancedByGrEu.coFinancedLinks}
        usolveLogo={usolveLogo}
      />
    </Container>
  </>
)};

export default CoFinancedByGREU;
