import React, { useEffect } from 'react';

const Beyond2021 = () => {
  useEffect(() => {
    if (!localStorage.qr) {
      localStorage.rq = 'Beyond-2021';
    }
    window.location.replace('/');
  }, [])
  return <p></p>
}

export default Beyond2021;
