import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { sliderImg } from '../images';

interface Props {

}

const Slider = (props: Props) => {
  const { } = props;
  return (
    <>
      <div className="sliderWrapper">
        <img className="sliderImg" src={sliderImg} alt="" />
      </div>
    </> 
  );
};

export default Slider;
