import React, { useEffect } from 'react';

const Flyer = () => {
  useEffect(() => {
    if (!localStorage.qr) {
      localStorage.rq = 'Flyer';
    }
    window.location.replace('/');
  }, [])
  return <p></p>
}

export default Flyer;
