import axios from "axios";
import _ from "lodash";
import {
  GET_USER_SELECT_INPUT,
  GET_USER_SEARCH_INPUT,
  GET_USER_POSTS,
  GET_USER_POSTS_LOADING,
  GET_USER_POSTS_ERROR,
  CLEAR_USER_POSTS,
  GET_SELECTED_CARD,
  SUBMIT_CONTACT_US_REQUEST_SUCCESS,
  SUBMIT_CONTACT_US_REQUEST_ERROR,
  SUBMIT_CONTACT_US_REQUEST,
} from "./types";

export const getUserSelectInput = (selectInput: string) => ({
  type: GET_USER_SELECT_INPUT,
  selectInput,
});

export const getUserSearchInput = (searchInput: string) => ({
  type: GET_USER_SEARCH_INPUT,
  searchInput,
});

export const getUserPosts = (
  selectInput: string,
  searchInput: string
) => async (dispatch: any) => {
  dispatch({
    type: GET_USER_POSTS_LOADING,
  });
  try {
    const data = await axios.post("http://localhost:2900/api/search", {
      selectInput: selectInput ? selectInput.toLowerCase() : "",
      searchInput,
    });
    if (data.data.status === 200) {
      dispatch({
        type: GET_USER_POSTS,
        posts: data.data.data,
        message: _.isEmpty(data.data.data.items)
          ? "No records were found. Please try a different search!"
          : "",
      });
    } else {
      dispatch({
        type: GET_USER_POSTS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_USER_POSTS_ERROR,
    });
  }
};

// not used
export const submitContactUsRequest = (req: {
  name: string;
  email: string;
  subject: string;
  message: string;
}) => async (dispatch: any) => {
  dispatch({
    type: SUBMIT_CONTACT_US_REQUEST,
  });
  try {
    const data = await axios.post(
      "https://s56lwucnx4.execute-api.eu-west-1.amazonaws.com/dev/contact/contactUs",
      { ...req, termsAndConditions: true }
    );
    if (data.data.status === 200) {
      dispatch({
        type: SUBMIT_CONTACT_US_REQUEST_SUCCESS,
        message: "Your request was submitted successfully",
      });
    } else {
      dispatch({
        type: SUBMIT_CONTACT_US_REQUEST_ERROR,
        message: "Could not submit contact request, try again later!",
      });
    }
  } catch (e) {
    dispatch({
      type: GET_USER_POSTS_ERROR,
    });
  }
};

export const clearUserPosts = () => ({
  type: CLEAR_USER_POSTS,
});

export const getSelectedCard = (selectedCard: string) => ({
  type: GET_SELECTED_CARD,
  selectedCard,
});
