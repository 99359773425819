import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  Home, About, ContactUs, PrivacyPolicies, TermsAndConditions, NotFound,
} from './pages';
import './App.scss';
import './fonts/FSElliotPro-Regular.otf';
import Cookie from './components/cookie';
import Paschalidis from './pages/qr/paschalidis';
import Beyond2021 from './pages/qr/beyond-2021';
import Vasileiadis from './pages/qr/vasileiadis';
import Flyer from './pages/qr/flyers';
import CoFinancedByGREU from "./pages/coFinancedByGREU";

function App() {

  useEffect(() => {
    // ReactGA.initialize('G-KL7HDS991M');
  }, [])

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/contact-us">
            <ContactUs />
          </Route>
          <Route exact path="/privacy-policies">
            <PrivacyPolicies />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/co-financed-by-gr-eu">
            <CoFinancedByGREU />
          </Route>

          {/* qr */}
          <Route exact path="/qr/paschalidis">
            <Paschalidis />
          </Route>
          <Route exact path="/qr/beyond-2021">
            <Beyond2021 />
          </Route>
          <Route exact path="/qr/vasileiadis">
            <Vasileiadis />
          </Route>
          <Route exact path="/qr/flyer">
            <Flyer />
          </Route>

          {/* error pages */}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>

      <Cookie />
    </>
  );
}

export default App;
