import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import NavBar from '../components/navBar';
import Hero from '../components/hero';
import CardDeck from '../components/cardDeck';
import Footer from '../components/footer';
import { constants, home } from '../content';
import {
  logoDarkImg,
  logoWhiteImg,
  heroMainImg,
  hero01Img,
  hero02Img,
  hero03Img,
  facebookFooterImg,
  twitterFooterImg,
  instagramFooterImg,
  linkedinFooterImg,
  youtubeFooterImg,
  eBannerEU,
  usolveLogo
} from '../images';
import { getSelectedCard } from '../redux/upiria/actions';
import SubscribeSliderSection from '../components/subscribeSliderSection';

interface Props {
  selectedCard: string;
  updateSelectedCard: Function;
}

const Home = (props: Props) => {

  const { selectedCard, updateSelectedCard } = props;

  const onSelectedCardClick = (value: string) => {
    updateSelectedCard(value);
  };

  return (
    <>
      <NavBar image={logoDarkImg} optionsArray={constants.navBar} />

      <section>
        <Container>
          <Hero
            title={home.firstSlide.title}
            titleSize="l"
            paragraph={home.firstSlide.paragraph}
            image={heroMainImg}
            input
            inputPlaceholderText={home.firstSlide.inputPlaceholder}
            inputButtonText={home.firstSlide.inputButton}
            buttons
            buttonsHelperText={home.firstSlide.buttonsHelperText}
            consent={home.firstSlide.consent}
            view="left"
            type="home"
            titleMaxWidth="572px"
          />
        </Container>
      </section>

      <section>
        <Container className="md">
          <Hero
            title={home.secondSlide.title}
            titleSize="m"
            paragraph={home.secondSlide.paragraph}
            image={hero01Img}
            input={false}
            buttons={false}
            view="right"
            type="home"
            titleMaxWidth="472px"
          />
          <Hero
            title={home.thirdSlide.title}
            titleSize="m"
            paragraph={home.thirdSlide.paragraph}
            image={hero02Img}
            input={false}
            buttons={false}
            view="left"
            type="home"
            titleMaxWidth="472px"
          />
          <Hero
            title={home.fourthSlide.title}
            titleSize="m"
            paragraph={home.fourthSlide.paragraph}
            image={hero03Img}
            input={false}
            buttons={false}
            view="right"
            type="home"
            titleMaxWidth="472px"
          />
        </Container>
      </section>

      <section>
        <Container>
          <CardDeck
            cardDeck={home.cardDeck}
            onClick={onSelectedCardClick}
            selectedCard={selectedCard}
          />
        </Container>
      </section>

      <SubscribeSliderSection />

      <section>
        <Container>
          <Footer
            image={logoWhiteImg}
            paragraph={constants.footer.paragraph}
            paragraphEU={constants.footer.paragraphEU}
            imageEU={eBannerEU}
            menuTitle={constants.footer.menuTitle}
            menuLinks={constants.footer.menuLinks}
            followTitle={constants.footer.followTitle}
            followLinks={constants.footer.followLinks}
            followImageArray={[
              facebookFooterImg,
              twitterFooterImg,
              instagramFooterImg,
              linkedinFooterImg,
              youtubeFooterImg,
            ]}
            contactTitle={constants.footer.contactTitle}
            contactLinks={constants.footer.contactLinks}
            copyright={constants.footer.copyright}
            privacyLinks={constants.footer.privacyLinks}
            termsLinks={constants.footer.termsLinks}
            coFinancedLinks={constants.coFinancedByGrEu.coFinancedLinks}
            usolveLogo={usolveLogo}
          />
        </Container>
      </section>
    </>
  );
};

// <CardFeatured
//   title={home.cardFeatured.title}
//   cardFeatured={home.cardFeatured.cards}
//   onClick={onFeaturedCardClick}
//   linkText={home.cardFeatured.link}
//   selectedFeaturedCard={null}
// />

const mapStateToProps = (state: any) => ({
  selectedCard: state.upiria.selectedCard,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateSelectedCard: (selectedCard: string) => dispatch(getSelectedCard(selectedCard)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
