import React from 'react';
import Nav from 'react-bootstrap/Nav';

interface Props {
  title: string;
  description: string;
  buttonText: string;
}

const Message = (props: Props) => {
  const { title, description, buttonText } = props;

  return (
    <>
      <div className="messageWrapper">
        <h1 className="messageTitle">{title}</h1>
        <h2 className="messageDescription">{description}</h2>
        <Nav.Link className="messageButton" href="/">
          <span className="messageButtonText">{buttonText}</span>
        </Nav.Link>
      </div>
    </>
  );
};

export default Message;
