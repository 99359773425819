import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import axios from 'axios';
import withReactContent from 'sweetalert2-react-content';
import { constants } from '../content';

type Props = {
  consent?: boolean;
  emailInputPlaceholder?: string;
  centered?: boolean;
  submitButtonLabel?: string;
  submitButtonClass?: string;
  blockSubmitButton?: boolean;
  consentLabelClass?: string;
  concentCheckboxClass?: string;
}
const Subscribe = ({
  centered = true,
  consent = true,
  emailInputPlaceholder = 'E-Mail',
  submitButtonLabel = 'Subscribe',
  submitButtonClass = 'heroInputButton',
  blockSubmitButton = window.innerWidth < 576,
  consentLabelClass = '',
  concentCheckboxClass = '',
}: Props) => {
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const formStyles: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: centered ? 'center' : 'flex-start',
    justifyContent: 'center',
  }

  const resetSubscribeForms = () => {
    const forms = document.querySelectorAll<HTMLFormElement>('[data-id="subscribeForm"]');
    if (forms && forms.length) {
      forms.forEach((form) => {
        form.reset();
      });
    }
  }
  const handleFieldChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData({ ...formData, [name]: value || checked });
  };
  const swal = withReactContent(Swal);
  const submit = (request: { email: string }) => {
    setLoading(true);
    axios
      .post(
        'https://s56lwucnx4.execute-api.eu-west-1.amazonaws.com/dev/contact/subscribe',
        { ...request, termsAndConditions: true },
      )
      .then((res) => {
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        resetSubscribeForms();
        swal.fire({
          icon: 'success',
          title: 'Your application has been sent successfully!',
          confirmButtonText: 'Got it',
        });
      });
  };

  const renderSubmitButton = () => {
    const blockStyles: React.CSSProperties = {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    };
    const inlineStyles: React.CSSProperties = {
      width: 'auto',
    };
    return (
      <button disabled={loading} type="submit" className={submitButtonClass} style={blockSubmitButton ? blockStyles : inlineStyles}>
        <p className="heroInputButtonText">
          {loading ? 'Sending...' : submitButtonLabel}
        </p>
      </button>
    )
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    submit(formData);
  };

  return (
    <div style={{ width: '100%' }} className="subscribeWrapper">
      <Form style={formStyles} data-id="subscribeForm" onSubmit={handleSubmit}>
        <div className="heroInputWrapper">
          <input
            className="heroInput"
            type="email"
            required
            id="email"
            name="email"
            placeholder={emailInputPlaceholder}
            onChange={handleFieldChange}
          />

          {
            !blockSubmitButton && renderSubmitButton()
          }
        </div>
        {
          consent && (
            <div className="heroConsent">
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  required
                  className={concentCheckboxClass}
                />
                <Form.Check.Label>
                  <p className={consentLabelClass} style={{marginTop: '3px'}}>
                    I agree with the
                    {' '}
                    <a href={constants.footer.termsLinks.link}>
                      {constants.footer.termsLinks.text}
                    </a>
                    {' '}
                    and
                    {' '}
                    <a href={constants.footer.privacyLinks.link}>
                      {constants.footer.privacyLinks.text}
                    </a>
                    .
                  </p>
                </Form.Check.Label>

              </Form.Check>
            </div>
          )
        }

        {
          blockSubmitButton && renderSubmitButton()
        }

      </Form>
    </div>
  );
};

export default Subscribe;
