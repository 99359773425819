import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface Options {
  label: string;
  value: string;
}

interface CardForm {
  title: string;
  inputName: string;
  inputEmail: string;
  inputSubject: string;
  inputSubjectOptions: Options[];
  inputMessage: string;
  termsAndConditions: string;
  termsAndConditionsLink: string;
  privacyPolicy: string;
  privacyPolicyLink: string;
  inputButton: string;
}

interface ContactCard {
  title: string;
  subtitle: string;
  submittedTitle: string;
  submittedSubtitle: string;
  form: CardForm;
}

interface Props {
  contactCard: ContactCard[];
}



const getMappedCards = (cards: ContactCard[]) => cards.map((card: ContactCard) => {

  const [contactUsForm, setContactUsForm] = useState<any>({});
  // TODO get from store
  const [submited, setSubmited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const swal = withReactContent(Swal)

  const resetContactUsForm = () => {
    const form = document.getElementById('contactUsRequestForm') as HTMLFormElement;
    // eslint-disable-next-line no-unused-expressions
    form?.reset?.();
  }
  const submitContactUsRequest = (req: {
    name: string;
    email: string;
    subject: string;
    message: string;
  }) =>  {
    setLoading(true);
    axios.post('https://s56lwucnx4.execute-api.eu-west-1.amazonaws.com/dev/contact/contactUs', {...req, termsAndConditions: true})
      .then(res => {
        setSubmited(true);
        
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        resetContactUsForm();
        swal.fire({
          icon: 'success',
          title: 'Your application has been sent successfully!',
          confirmButtonText: 'Got it',
        });
      });
  };
 
  const handleFeildChange = (e: any) => {
    const {name, value, checked} = e.target;
    setContactUsForm({...contactUsForm, [name]: value || checked});
  }
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitContactUsRequest(contactUsForm);
  };
  return (

    <Card className="contactCard">
      <Row>
        <Col>
          <div className="contactCardTextWrapper">
            <h1 className="contactCardTitle">{card.title}</h1>
            <p className="contactCardSubtitle">{card.subtitle}</p>
          </div>
        </Col>
        <Col>
          <div className="contactCardFormWrapper">
            <h3 className="contactCardFormTitle">{card.form.title}</h3>
            <Form id="contactUsRequestForm" onSubmit={handleSubmit}>
              <Row>
                <Col><input type="text" name="name" required className="contactCardFormInput" id="inputName" placeholder={card.form.inputName} onChange={handleFeildChange}/></Col>
                <Col><input type="email" name="email" required className="contactCardFormInput" id="inputEmail" placeholder={card.form.inputEmail} onChange={handleFeildChange}/></Col>
              </Row>
              <Row>
                <Col>
                  <input type="text" name="subject" required className="contactCardFormInput" id="inputSubject" placeholder={card.form.inputSubject} onChange={handleFeildChange}/>
                </Col>
              </Row>
              <Row>
                <Col><textarea name="message" required className="contactCardFormInput contactCardFormInputTextArea" id="inputMessage" placeholder={card.form.inputMessage} onChange={handleFeildChange}/></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check className="contactCardFormCheck">
                    <Form.Check.Input required className="contactCardFormCheckbox" type="checkbox"/>
                    <Form.Check.Label>
                      <p className="contactCardText">{card.form.termsAndConditions} <a className="contactCardText" href="/terms-and-conditions">{card.form.termsAndConditionsLink}</a> {card.form.privacyPolicy} <a className="contactCardText" href="/privacy-policies">{card.form.privacyPolicyLink}</a></p>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button type="submit" disabled={loading} className="contactCardFormButton"><p className="contactCardFormButtonText">{loading ? 'Sending...' : card.form.inputButton}</p></button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Card>
    
  )
})

const ContactCard = (props: Props) => {
  const { contactCard } = props;
  return (
    <>
      <div className="contactCardWrapper">
        {getMappedCards(contactCard)}
      </div>
    </>
  );
};


export default ContactCard;
