import React from 'react';

interface Grouped {
  numberedDescription: string[];
  description?: string[];
}

interface BulletPoint {
  title: string;
  description: string[];
  numberedDescription?: string[];
}

interface Item {
  title: string;
  description?: string[];
  numberedDescription?: string[];
  bulletPointDescription?: BulletPoint[];
  groupedDescription?: Grouped[];
}

interface Props {
  title: string;
  description: string[];
  subtitle?: string;
  items: Item[];
}

const getMappedParagraphs = (
  paragraph: string[],
  className: string
) => paragraph.map((p: string) => (
  <p className={className}>{p}</p>
));

const getMappedBulletPoints = (
  bulletPoints: BulletPoint[],
) => bulletPoints.map((b: BulletPoint) => (
  <ul className="articleItemsBulletPointWrapper">
    <li className="articleItemsBulletPointTitle">{b.title}</li>
    <p>{getMappedParagraphs(b.description, 'articleItemsBulletPointDescription')}</p>
    {b.numberedDescription ? getMappedParagraphs(b.numberedDescription, 'articleItemsBulletPointNumberedDescription') : ''}
  </ul>
));

const getMappedGroupedDescription = (
  bulletPoints: Grouped[],
) => bulletPoints.map((g: Grouped) => (
  <div>
    {getMappedParagraphs(g.numberedDescription, 'articleItemsGroupedNumberedDescription')}
    {g.description ? getMappedParagraphs(g.description, 'articleItemsGroupedDescription') : ''}
  </div>
));


const Article = (props: Props) => {
  const {
    title,
    description,
    subtitle,
    items,
  } = props;

  const mItems = items.map((n: Item, i: number) => (
    <div className="articleItemsWrapper">
      <div className="articleItemsTitleWrapper">
        {n.title !== 'General' ? <h1 className="articleItemsTitleNumber">{i + 1}.</h1> : ''}
        <h1 className="articleItemsTitle">{n.title}</h1>
      </div>
      <div className="articleItemsDescriptionWrapper">
        {n.description ? getMappedParagraphs(n.description, 'articleItemsDescription') : ''}
        {n.numberedDescription ? getMappedParagraphs(n.numberedDescription, 'articleItemsNumberedDescription') : ''}
        {n.bulletPointDescription ? getMappedBulletPoints(n.bulletPointDescription) : ''}
        {n.groupedDescription ? getMappedGroupedDescription(n.groupedDescription) : ''}

      </div>
    </div>
  ))

  return (
    <>
      <div className="articleWrapper">
        <h1 className="articleTitle">{title}</h1>
        <p className="articleDescription">{getMappedParagraphs(description, '')}</p>
      </div>
      <div className="articleTextWrapper">
        <h1 className="articleSubtitle">{subtitle}</h1>
        {mItems}
      </div>
    </>
  );
};

Article.defaultProps = {
  subtitle: '',
}

export default Article;
