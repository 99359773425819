import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from '../components/navBar';
import IconHeader from '../components/iconHeader';
import Hero from '../components/hero';
import CardFeatured from '../components/cardFeatured';
import Footer from '../components/footer';
import { constants, about } from '../content';
import {
  logoDarkImg,
  logoWhiteImg,
  heartImg,
  handshakeImg,
  shieldImg,
  heroKayakImg,
  heroHikeImg,
  pavlosvImg,
  pavlospImg,
  facebookFooterImg,
  twitterFooterImg,
  instagramFooterImg,
  linkedinFooterImg,
  youtubeFooterImg,
  eBannerEU,
  usolveLogo
} from '../images';
import SubscribeSliderSection from '../components/subscribeSliderSection';

const About = () => {
  return (
    <>
      <NavBar
        image={logoDarkImg}
        optionsArray={constants.navBar}
      />
      <section>
        <Container>
          <IconHeader
            title={about.icons.title}
            iconArray={about.icons.iconArray}
            imageArray={[heartImg, handshakeImg, shieldImg]}
          />
        </Container>
      </section>

      <section className="about-us-heroes-section">
        <Container>
          <Hero
            title={about.firstSlide.title}
            titleSize="m"
            paragraph={about.firstSlide.paragraph}
            imageTitle={about.firstSlide.imageTitle}
            image={heroKayakImg}
            input={false}
            buttons={false}
            view="right"
            type="about"
          />
          <Hero
            title={about.secondSlide.title}
            titleSize="m"
            paragraph={about.secondSlide.paragraph}
            imageTitle={about.secondSlide.imageTitle}
            image={heroHikeImg}
            input={false}
            buttons={false}
            view="left"
            type="about"
          />
        </Container>
      </section>

      <section>
        <Container>
          <CardFeatured
            title={about.cardFeatured.title}
            linkText=""
            cardFeatured={about.cardFeatured.cards}
            images={[pavlosvImg, pavlospImg]}
            onClick={() => { }}
          />
        </Container>
      </section>

      <SubscribeSliderSection />

      <section>
        <Container>
          <Footer
            image={logoWhiteImg}
            paragraph={constants.footer.paragraph}
            paragraphEU={constants.footer.paragraphEU}
            imageEU={eBannerEU}
            menuTitle={constants.footer.menuTitle}
            menuLinks={constants.footer.menuLinks}
            followTitle={constants.footer.followTitle}
            followLinks={constants.footer.followLinks}
            followImageArray={[
              facebookFooterImg,
              twitterFooterImg,
              instagramFooterImg,
              linkedinFooterImg,
              youtubeFooterImg,
            ]}
            contactTitle={constants.footer.contactTitle}
            contactLinks={constants.footer.contactLinks}
            copyright={constants.footer.copyright}
            privacyLinks={constants.footer.privacyLinks}
            termsLinks={constants.footer.termsLinks}
            coFinancedLinks={constants.coFinancedByGrEu.coFinancedLinks}
            usolveLogo={usolveLogo}
          />
        </Container>
      </section>
    </>
  )
};

export default About;
