import React, { useEffect } from 'react';

const Paschalidis = () => {
  useEffect(() => {
    if (!localStorage.qr) {
      localStorage.qr = 'Paschalidis';
    }
    window.location.replace('/');
  }, [])
  return <p></p>
}

export default Paschalidis;
