import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from '../components/navBar';
import Article from '../components/article';
import Footer from '../components/footer';
import { constants, termsAndConditions } from '../content';
import {
  logoDarkImg,
  logoWhiteImg,
  facebookFooterImg,
  twitterFooterImg,
  instagramFooterImg,
  linkedinFooterImg,
  youtubeFooterImg,
  eBannerEU,
  usolveLogo
} from '../images';

const PrivacyPolicy = () => {

  return (
  <>
    <NavBar
      image={logoDarkImg}
      optionsArray={constants.navBar}
    />
    <Container>
      <Article
        title={termsAndConditions.title}
        description={termsAndConditions.description}
        items={termsAndConditions.items}
      />
      <Footer
        image={logoWhiteImg}
        paragraph={constants.footer.paragraph}
        paragraphEU={constants.footer.paragraphEU}
        imageEU={eBannerEU}
        menuTitle={constants.footer.menuTitle}
        menuLinks={constants.footer.menuLinks}
        followTitle={constants.footer.followTitle}
        followLinks={constants.footer.followLinks}
        followImageArray={[facebookFooterImg, twitterFooterImg, instagramFooterImg, linkedinFooterImg, youtubeFooterImg]}
        contactTitle={constants.footer.contactTitle}
        contactLinks={constants.footer.contactLinks}
        copyright={constants.footer.copyright}
        privacyLinks={constants.footer.privacyLinks}
        termsLinks={constants.footer.termsLinks}
        coFinancedLinks={constants.coFinancedByGrEu.coFinancedLinks}
        usolveLogo={usolveLogo}
      />
    </Container>
  </>
)};

export default PrivacyPolicy;
