import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  appleImg,
  playImg,
  eBannerEU, usolveLogo,
} from '../images';
import Subscribe from './subscribe';

interface Props {
  title: string;
  titleSize: 'l' | 'm';
  paragraph: string[];
  imageTitle?: string;
  image: string;
  input: boolean;
  inputPlaceholderText?: string;
  inputButtonText?: string;
  buttons: boolean;
  buttonsHelperText?: string;
  consent?: string;
  view: 'right' | 'left';
  type: 'home' | 'about';
  titleMaxWidth?: string;
}

const Hero = (props: Props) => {
  const {
    title,
    titleSize,
    paragraph,
    imageTitle,
    image,
    input,
    inputPlaceholderText,
    inputButtonText,
    buttons,
    buttonsHelperText,
    consent,
    view,
    type,
    titleMaxWidth = 'unset'
  } = props;

  const mParagraph = paragraph.map((p: string) => (
    <p className="heroParagraph">{p}</p>
  ));

  const textDiv = (
    <>
      <div>
        <h1 className={titleSize === 'l' ? 'heroTitleL' : 'heroTitleM'} style={{maxWidth: titleMaxWidth}}>{title}</h1>
        <div className="heroSubtitle">{mParagraph}</div>
      </div>
      {
        input
          ? (
            <Subscribe consent={!!consent} centered={false} emailInputPlaceholder={inputPlaceholderText} submitButtonLabel={inputButtonText} />
          )
          : <></>
      }
      {
        buttons
          ? (
            <>
              <div className="heroButtons">
                <p className="heroHelperText">{buttonsHelperText}</p>
                <a href="https://apps.apple.com/us/app/upiria-tours-activities/id6449971079" target="_blank" className="appleStoreButton"><img src={appleImg} /><p className="appleStoreButtonText">App Store</p></a>
                <a href="https://play.google.com/store/apps/details?id=com.upiria.app" target="_blank" className="playStoreButton"><img src={playImg} /><p className="googlePlayStoreButtonText">Play Market</p></a>
              </div>
              <div className="heroButtonsEU">
                <p className="heroSubtitle">Upiria P.C. is co-financed by Greece and the European Union - European Regional Development Fund.</p>
                <a className="row heroImageEU" href="/co-financed-by-gr-eu">
                  <img src={eBannerEU} width="151" height="74" alt=""/>
                </a>
              </div>
              <div className="heroButtonsEU">
                <p className="heroSubtitle">Upiria P.C. received support from U-SOLVE and the program ENI CBC MED.</p>
                <img src={usolveLogo} width="230" height="98" alt=""/>
              </div>
            </>
          )
          : <></>
      }
    </>
  )

  const imageDiv = (
    <>
      <div className="heroImagewrapper">
        {imageTitle ? <p className={`heroImageText ${view === 'left' ? 'heroImageTextRight' : 'heroImageTextLeft'}`}>{imageTitle || ''}</p> : ''}
        <img className={view === 'left' ? 'heroImageLeft' : 'heroImageRight'} alt="" src={image} />
      </div>
    </>
  )

  return (
    <>
      <Row className={`heroWrapper ${type === 'home' ? 'heroWrapperCenter' : ''} ${titleSize === 'l' ? 'heroWrapperMain' : ''}`}>
        <Col className={view === 'left' ? 'heroText' : 'heroImage'}>{textDiv}</Col>
        {titleSize === 'l' ? '' : <Col className={view === 'left' ? 'heroImage' : 'heroText'}>{imageDiv}</Col>}
        {titleSize === 'l' ? <img className="heroMainImg" alt="" src={image} /> : ''}
      </Row>
    </>
  );
};

export default Hero;
