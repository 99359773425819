import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Subscribe from './subscribe';

interface ApplicationForm {
  inputEmail: string;
  termsAndConditions: string;
  termsAndConditionsLink: string;
  privacyPolicy: string;
  privacyPolicyLink: string;
  inputButton: string;
}

interface ApplicationCard {
  title: string;
  form: ApplicationForm;
}

interface Props {
  applicationCard: ApplicationCard[];
  image: string;
}

const getMappedCards = (cards: ApplicationCard[], image: string) => cards.map((card: ApplicationCard) => {
  return (
    <Card className="applicationCard">
      <Row>
        <div className="applicationCardTextWrapper">
          <img className="applicationCardLogo" src={image} alt="" />
          <h1 className="applicationCardTitle">{card.title}</h1>
        </div>
        <Col>
          <div className="applicationCardFormWrapper">
            <Subscribe
              centered={false}
              concentCheckboxClass="applicationCardFormCheckbox"
              consentLabelClass="applicationCardText"
            />
          </div>
        </Col>
        <Col>
        </Col>
      </Row>
    </Card>
  )
})

const ApplicationCard = (props: Props) => {
  const { applicationCard, image } = props;
  return (
    <>
      <div className="applicationCardWrapper">
        {getMappedCards(applicationCard, image)}
      </div>
    </>
  );
};

export default ApplicationCard;
