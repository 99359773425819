import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

interface Props {

}
const Cookie = (props: Props) => {
  const [acceptCookie, setAcceptCookie] = useState(localStorage.acceptCookie);
  const handleAcceptCookie = () => {
    localStorage.acceptCookie = 'yes';
    setAcceptCookie('yes');
  }
  const { } = props;
  return (
    <>
      {!acceptCookie &&
        (
          <div className="cookieWrapper">
            <p>
              We use cookies (and other similar technologies) to improve your experience on our site.
            </p>
            <Button className="heroInputButton" onClick={handleAcceptCookie}>Accept</Button>
          </div>
        )}
    </>
  );
};

export default Cookie;
