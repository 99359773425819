import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'

interface Icon {
  title: string;
  description: string;
  image: number;
}

interface Props {
  title: string;
  iconArray: Icon[];
  imageArray: string[];
}

const getMappedIcons = (
  iconArray: Icon[],
  imageArray: string[]
) => iconArray.map((icon: Icon) => (
  <div className="iconArrayIndividualWrapper">
    <img className="iconArrayImg" alt="" src={imageArray[icon.image]} />
    <p className="iconArrayTitle">{icon.title}</p>
    <p className="iconArrayDescription">{icon.description}</p>
  </div>
));

const IconHeader = (props: Props) => {
  const { title, iconArray, imageArray } = props;

  return (
    <>
      <div className="iconHeaderWrapper">
        <div className="iconHeaderTitleWrapper">
          <h1 className="iconHeaderTitle">{title}</h1>
        </div>
        <div className="iconArrayWrapper">
          {getMappedIcons(iconArray, imageArray)}
        </div>
      </div>
    </>
  );
};

export default IconHeader;
