import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

interface CardDeckI {
  title: string;
  paragraph: string;
  number: string;
}

interface Props {
  cardDeck: CardDeckI[];
  onClick: any;
  selectedCard: string;
}

const getZIndexes = (selectedCard: string, i: number) => {
  if (selectedCard === '01') {
    if (i === 0) {
      return 3;
    } if (i === 1) {
      return 2;
    } if (i === 2) {
      return 1;
    }
  } else if (selectedCard === '02') {
    if (i === 0) {
      return 2;
    } if (i === 1) {
      return 3;
    } if (i === 2) {
      return 1;
    }
  } else if (selectedCard === '03') {
    if (i === 0) {
      return 1;
    } if (i === 1) {
      return 2;
    } if (i === 2) {
      return 3;
    }
  }
  return 1;
}

const getMappedCards = (
  cards: CardDeckI[],
  onClick: any, selectedCard: string,
) => cards.map((card: CardDeckI, i: number) => {
  const marginTop = (i > 0) ? '-450px' : '0px';
  const zIndex = getZIndexes(selectedCard, i);
  return (
    <Card
      key={card.number}
      style={{
        zIndex,
        marginTop,
        width: '100%',
        height: '100%',
        background: '#FFFFFF',
        borderRadius: '32px',
        boxShadow: `0px 45px 183px rgba(5, 2, 61, 0.06),
        0px 18.7999px 76.453px rgba(5, 2, 61, 0.0431313),
        0px 10.0513px 40.8754px rgba(5, 2, 61, 0.0357664),
        0px 5.6347px 22.9144px rgba(5, 2, 61, 0.03),
        0px 2.99255px 12.1697px rgba(5, 2, 61, 0.0242336),
        0px 1.24527px 5.06408px rgba(5, 2, 61, 0.0168687)`,
      }}
      onClick={() => onClick(card.number)}
    >
      <Row>
        <Col>
          <h1 className="cardDeckTitle">{card.title}</h1>
        </Col>
        <Col>
          <p className="cardDeckParagraph" style={{fontSize: 'larger'}}>{card.paragraph}</p>
        </Col>
      </Row>
      <p className="cardDeckNumber">{card.number}</p>
    </Card>
  )
})

const CardDeck = (props: Props) => {
  const { cardDeck, onClick, selectedCard } = props;
  return (
    <>
      <div className="cardDeckWrapper">
        {getMappedCards(cardDeck, onClick, selectedCard)}
      </div>
    </>
  );
};

export default CardDeck;
