export const GET_USER_SELECT_INPUT = "GET_USER_SELECT_INPUT";
export const GET_USER_SEARCH_INPUT = "GET_USER_SEARCH_INPUT";
export const GET_USER_POSTS = "GET_USER_POSTS";
export const GET_USER_POSTS_ERROR = "GET_USER_POSTS_ERROR";
export const GET_USER_POSTS_LOADING = "GET_USER_POSTS_LOADING";
export const CLEAR_USER_POSTS = "CLEAR_USER_POSTS";
export const GET_SELECTED_CARD = "GET_SELECTED_CARD";
export const SUBMIT_CONTACT_US_REQUEST = "SUBMIT_CONTACT_US_REQUEST";
export const SUBMIT_CONTACT_US_REQUEST_ERROR =
  "SUBMIT_CONTACT_US_REQUEST_ERROR";
export const SUBMIT_CONTACT_US_REQUEST_SUCCESS =
  "SUBMIT_CONTACT_US_REQUEST_SUCCESS";
