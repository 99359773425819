import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from '../components/navBar';
import ContactCard from '../components/contactCard';
import Questions from '../components/questions';
import ApplicationCard from '../components/applicationCard';
import Footer from '../components/footer';
import { constants, contactUs } from '../content';
import {
  logoDarkImg,
  logoWhiteImg,
  facebookFooterImg,
  twitterFooterImg,
  instagramFooterImg,
  linkedinFooterImg,
  youtubeFooterImg,
  eBannerEU,
  usolveLogo
} from '../images';

const ContactUs = () => {
  return (
    <>
      <NavBar
        image={logoDarkImg}
        optionsArray={constants.navBar}
      />
      <Container>
        <ContactCard
          contactCard={contactUs.contactCard}
        />

        {/* hide FAQ */}
        {
          false &&
          (
            <Questions
              title={contactUs.faq.title}
              questions={contactUs.faq.questions}
              columns={2}
            />
          )
        }

        <ApplicationCard
          image={logoDarkImg}
          applicationCard={contactUs.applicationCard}
        />
        <Footer
          image={logoWhiteImg}
          paragraph={constants.footer.paragraph}
          paragraphEU={constants.footer.paragraphEU}
          imageEU={eBannerEU}
          menuTitle={constants.footer.menuTitle}
          menuLinks={constants.footer.menuLinks}
          followTitle={constants.footer.followTitle}
          followLinks={constants.footer.followLinks}
          followImageArray={[facebookFooterImg, twitterFooterImg, instagramFooterImg, linkedinFooterImg, youtubeFooterImg]}
          contactTitle={constants.footer.contactTitle}
          contactLinks={constants.footer.contactLinks}
          copyright={constants.footer.copyright}
          privacyLinks={constants.footer.privacyLinks}
          termsLinks={constants.footer.termsLinks}
          coFinancedLinks={constants.coFinancedByGrEu.coFinancedLinks}
          usolveLogo={usolveLogo}
        />
      </Container>
    </>
  )
};

export default ContactUs;
