import React from 'react';

interface Props {
  title: string;
  subtitle: string;
  description: string;
  subtitleGr: string;
  descriptionGr: string;
  imageElavateWebEN: string;
  imageElavateWeb: string;
}

const CoFinanced = (props: Props) => {
  const {
    title,
    subtitle,
    description,
    subtitleGr,
    descriptionGr,
    imageElavateWebEN,
    imageElavateWeb
  } = props;

  return (
    <>
      <div className="articleWrapper">
        <h1>{title}</h1>
        <h5>{subtitle}</h5>
        <p className="articleDescription">{description}</p>
      </div>
      <div>
        <a href={imageElavateWebEN} target="_blank">
          <img className="imgElavateWebEN" src={imageElavateWebEN} alt=""/>
        </a>
      </div>
      <div className="articleWrapper">
        <h5>{subtitleGr}</h5>
        <p className="articleDescription">{descriptionGr}</p>
      </div>
      <div>
        <a href={imageElavateWeb} target="_blank">
          <img className="imgElavateWebEN" src={imageElavateWeb} alt=""/>
        </a>
      </div>
    </>
  );
};

export default CoFinanced;
