import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface Links {
  text: string;
  link: string;
  newTab?: boolean;
}

interface Props {
  image: string;
  paragraph: string;
  paragraphEU: string;
  imageEU: string;
  usolveLogo: string;

  menuTitle: string;
  menuLinks: Links[];

  followTitle: string;
  followLinks: Links[];
  followImageArray: string[];

  contactTitle: string;
  contactLinks: Links[];

  copyright: string;
  privacyLinks: Links;
  termsLinks: Links;
  coFinancedLinks: Links;
}

const getMappedLinks = (
  links: Links[],
  imageArray?: string[],
  start?: number,
  limit?: number,
) => {
  const s = start ? start : 0;
  const l = limit ? limit : links.length;
  const arr = [];
  for (let i = s; i < l; i++) {
    const temp = (
      <div>
        {imageArray ? <img className="footerLinkImage" alt="" src={imageArray[i]} /> : ''}
        <a className="footerLink" target={links[i].newTab ? '_blank' : undefined} href={links[i].link} key={links[i].text}>{links[i].text}</a>
        <br />
        <br />
      </div>
    )
    arr.push(temp);
  }
  return arr;
}


const Footer = (props: Props) => {
  const {
    image,
    paragraph,
    paragraphEU,
    imageEU,
    menuTitle,
    menuLinks,
    followTitle,
    followLinks,
    followImageArray,
    contactTitle,
    contactLinks,
    copyright,
    privacyLinks,
    termsLinks,
    coFinancedLinks,
    usolveLogo
  } = props;

  return (
    <>
      <div className="footer">
        <Row className="footerRow">
          <Col className="footerParagraphCol">
            <img className="footerImg" src={image} width="118" height="32" alt="" />
            <p className="footerParagraph">{paragraph}</p>
            <p className="footerParagraph">{paragraphEU}</p>
            <a href={coFinancedLinks.link} key={coFinancedLinks.text}><img src={imageEU} width="70" height="32" alt=""/></a>
            <div style={{ marginTop: 80}}>
              <img src={usolveLogo} width="230" height="98" alt="u-solve" />
            </div>
          </Col>
          <Col className="menuCol">
            <p className="footerTitle">{menuTitle.toUpperCase()}</p>
            {getMappedLinks(menuLinks)}
          </Col>
          <Col className="follow1Col">
            <p className="footerTitle">{followTitle.toUpperCase()}</p>
            {getMappedLinks(followLinks, followImageArray, 0, 3)}
          </Col>
          <Col className="follow2Col">
            <p className="footerTitle footerTitleNone">{followTitle.toUpperCase()}</p>
            {getMappedLinks(followLinks, followImageArray, 3, 5)}
          </Col>
          <Col className="contactCol">
            <p className="footerTitle">{contactTitle.toUpperCase()}</p>
            {getMappedLinks(contactLinks)}
          </Col>
        </Row>
        <Row className="footerRow">
          <Col className="footerCopyrightRow">
            <p className="footerCopyright">
              Copyright &copy;
              {copyright}
            </p>
          </Col>
          <Col className="footerPrivacyPoliciesRow">
            <a className="footerPrivacyPolicies" href={privacyLinks.link} key={privacyLinks.text}><p className="footerPrivacyPolicies">{privacyLinks.text}</p></a>
          </Col>
          <Col className="footerTermsAndConditionsRow">
            <a className="footerTermsAndConditions" href={termsLinks.link} key={termsLinks.text}><p className="footerTermsAndConditions">{termsLinks.text}</p></a>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
