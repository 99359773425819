import React from 'react';
import Container from 'react-bootstrap/Container';
import Slider from './slider';
import Subscribe from './subscribe';
import { logoDarkImg } from '../images';

const SubscribeSliderSection = () => {
  return (
    <section className="subscribeSection">
      <div className="subscribeSliderWrapper">
        <Slider />
        <Container>
          <div className="subscribeSliderInnerWrapper">
            <img className="subscribeLogoImg" src={logoDarkImg} alt="" />
            <h1 className="heroTitleM">
              Be the first to receive the application
            </h1>
            <Subscribe />
          </div>
        </Container>
      </div>
    </section>
  );
};

export default SubscribeSliderSection;
